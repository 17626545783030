import React from "react";
import { Link } from "gatsby";
import g from "glamorous";
import { css } from "glamor";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";

export default function Example() {
  return (
    <Wrapper>
      <Title>Toutes les interventions</Title>

      <Accordion allowZeroExpanded={true}>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              <Zone>
                Visage <Arrow />
              </Zone>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <Link
              {...cssLink}
              to="/visage/comblement-rides-acide-hyaluronique/"
            >
              Acide hyaluronique
            </Link>

            <Link {...cssLink} to="/visage/couperose-rosacee-erythrose-laser/">
              Couperose, rosacée et érythrose
            </Link>

            <Link {...cssLink} to="/visage/fils-tenseurs-silhouette-soft/">
              Fils tenseurs Silhouette Soft
            </Link>

            <Link {...cssLink} to="/visage/genioplastie/">
              G&eacute;nioplastie
            </Link>

            <Link {...cssLink} to="/visage/hydrafacial/">
              HydraFacial
            </Link>

            <Link {...cssLink} to="/visage/supprimer-rides-front/">
              Injections de botox
            </Link>

            <Link {...cssLink} to="/peau/keybody-skin/">
              Keybody-Skin
            </Link>

            <Link {...cssLink} to="/visage/lifting-cervico-facial/">
              Lifting cervico-facial
            </Link>

            <Link {...cssLink} to="/visage/lifting-du-cou-platysmaplastie/">
            Lifting du cou - Platysmaplastie
          </Link>

            <Link {...cssLink} to="/visage/lifting-visage-paris/">
              Lifting du visage LVPA
            </Link>

            <Link {...cssLink} to="/visage/mesolift-nctf/">
              M&eacute;solift NCTF
            </Link>

            <Link
              {...cssLink}
              to="/chirurgie-esthetique-sous-anesthesie-locale/#mini-lift"
            >
              Mini-lift
            </Link>

            <Link {...cssLink} to="/visage/peeling-combine/">
              Peeling combiné
            </Link>

            <Link {...cssLink} to="/peau/peeling-bioretivalisant-prx-t33/">
              Peeling biorevitalisant PRX-T33
            </Link>

            <Link {...cssLink} to="/regard/plexr/">
              Plexr®
            </Link>

            <Link {...cssLink} to="/visage/rajeunissement-cou-botox/">
              Rajeunissement du cou
            </Link>

            <Link {...cssLink} to="/visage/reduction-machoire-botox/">
              R&eacute;duction de la m&acirc;choire par botox
            </Link>

            <Link {...cssLink} to="/visage/comblement-rides-front/">
              Rides du front
            </Link>

            <Link {...cssLink} to="/actualites/sisthaema/">
              Sisthaema
            </Link>

            <Link {...cssLink} to="/visage/traitement-migraine-botox/">
              Traitement de la migraine (botox)
            </Link>

            <Link {...cssLink} to="/visage/bruxisme/">
              Traitement du bruxisme
            </Link>

            <Link {...cssLink} to="/visage/traitement-esthetique-levres/">
              Traitements des l&egrave;vres
            </Link>

            <Link {...cssLink} to="/visage/skinbooster/">
              Skinbooster
            </Link>

            <Link {...cssLink} to="/visage/supprimer-bajoues/">
              Supprimer les bajoues
            </Link>

            <Link {...cssLink} to="/visage/supprimer-double-menton/">
              Supprimer le double menton
            </Link>

            <Link {...cssLink} to="/visage/universkin/">
              Universkin : Cosmétique médicale
            </Link>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              <Zone>
                Regard <Arrow />
              </Zone>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <Link
              {...cssLink}
              to="/regard/blepharoplastie-chirurgie-paupieres/"
            >
              Bl&eacute;pharoplastie : Chirurgie des paupi&egrave;res
            </Link>
            <Link {...cssLink} to="/regard/blepharoplastie-asiatique/">
              Bl&eacute;pharoplastie asiatique
            </Link>
            <Link {...cssLink} to="/cernes/blepharoplastie-inferieure/">
              Bl&eacute;pharoplastie inférieure
            </Link>
            <Link
              {...cssLink}
              to="/cernes/comblement-cernes-creux-acide-hyaluronique/"
            >
              Comblement des cernes creux
            </Link>
            <Link {...cssLink} to="/regard/contour-des-yeux/">
              Contour des yeux
            </Link>

            <Link {...cssLink} to="/regard/lifting-du-front/">
              Lifting du front
            </Link>

            <Link {...cssLink} to="/cernes/lifting-malaire-cernes-creux/">
              Lifting malaire
            </Link>

            <Link {...cssLink} to="/cernes/lipostructure-des-cernes/">
              Lipostructure des cernes
            </Link>

            <Link {...cssLink} to="/cernes/peeling-depigmentant-cernes/">
              Peeling dépigmentant des cernes
            </Link>

            <Link {...cssLink} to="/regard/plexr/">
              Plexr®
            </Link>

            <Link {...cssLink} to="/regard/rajeunissement-front/">
              Rajeunissement du front
            </Link>

            <Link {...cssLink} to="/regard/patte-d-oie-botox/">
              Rides de la patte d'oie (Botox)
            </Link>

            <Link {...cssLink} to="/regard/rides-patte-d-oie/">
              Rides de la patte d'oie (Chirurgie)
            </Link>

            <Link {...cssLink} to="/visage/peeling-rides-patte-d-oie/">
              Rides de la patte d'oie (Peeling)
            </Link>

            <Link {...cssLink} to="/visage/comblement-rides-front/">
              Rides du front
            </Link>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              <Zone>
                Nez <Arrow />
              </Zone>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <Link {...cssLink} to="/nez/septoplastie/">
              Septoplastie : Déviation de la cloison nasale
            </Link>

            <Link {...cssLink} to="/nez/rhinoplastie/">
              Rhinoplastie : Chirurgie esthétique du nez
            </Link>

            <Link {...cssLink} to="/nez/rhinoplastie-ethnique/">
              Rhinoplastie ethnique
            </Link>

            <Link {...cssLink} to="/nez/rhinoplastie-medicale/">
              Rhinoplastie m&eacute;dicale (sans chirurgie)
            </Link>

            <Link {...cssLink} to="/nez/rhinoplastie-secondaire/">
              Rhinoplastie secondaire
            </Link>

            <Link {...cssLink} to="/nez/migraine-nasale/">
              Traitement de la migraine nasale
            </Link>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              <Zone>
                Oreilles <Arrow />
              </Zone>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>

            <Link {...cssLink} to="/lobes-oreilles/">
              Lobes d'oreilles fendus, déchirés ou allongés
            </Link>

            <Link {...cssLink} to="/oreilles/oreilles-decollees/">
              Otoplastie : Chirurgie des oreilles d&eacute;coll&eacute;es
            </Link>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              <Zone>
                Seins <Arrow />
              </Zone>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <Link
              {...cssLink}
              to="/chirurgie-esthetique-sous-anesthesie-locale/#augmentation-mammaire"
            >
              Augmentation mammaire sous anesthésie locale
            </Link>

            <Link {...cssLink} to="/seins/lipostructure/">
              Lipostructure des seins
            </Link>

            <Link {...cssLink} to="/seins/augmentation-macrolane/">
              Macrolane
            </Link>

            <Link {...cssLink} to="/silhouette/mommy-makeover/">
              Mommy makeover
            </Link>

            <Link {...cssLink} to="/seins/protheses-mammaires/">
              Proth&egrave;ses mammaires
            </Link>

            <Link
              {...cssLink}
              to="/seins/rajeunissement-mammaire-correction-ptose/"
            >
              Rajeunissement des seins : Correction de ptôse mammaire
            </Link>

            <Link {...cssLink} to="/seins/reconstruction-mammaire/">
              Reconstruction mammaire
            </Link>

            <Link {...cssLink} to="/seins/reduction-mammaire/">
              R&eacute;duction mammaire
            </Link>

            <Link {...cssLink} to="/seins/retirer-implants-mammaires/">
              Retirer ses implants mammaires
            </Link>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              <Zone>
                Bras <Arrow />
              </Zone>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <Link {...cssLink} to="/silhouette/cryolipolyse-coolsculpting/">
              Cryolipolyse avec Coolsculpting
            </Link>
            <Link {...cssLink} to="/bras/decompression-nerf-cubital/">
              Décompression du nerf cubital
            </Link>
            <Link {...cssLink} to="/bras/lipoaspiration/">
              Lipoaspiration des bras
            </Link>
            <Link {...cssLink} to="/bras/lifting-plastie/">
              Lifting des bras - Plastie des bras
            </Link>
            <Link {...cssLink} to="/silhouette/onda-coolwaves/">
              ONDA Coolwaves
            </Link>
            <Link {...cssLink} to="/actualites/sisthaema/">
              Sisthaema
            </Link>
            <Link {...cssLink} to="/bras/syndrome-canal-carpien/">
              Syndrome du Canal Carpien : décompression du nerf médian
            </Link>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              <Zone>
                Silhouette <Arrow />
              </Zone>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <Link {...cssLink} to="/silhouette/bodylift/">
              Bodylift
            </Link>

            <Link {...cssLink} to="/silhouette/cryolipolyse-coolsculpting/">
              Coolsculpting
            </Link>

            <Link {...cssLink} to="/silhouette/emsculpt/">
              EMSculpt
            </Link>

            <Link {...cssLink} to="/silhouette/implants-fessiers/">
              Implants fessiers
            </Link>

            <Link {...cssLink} to="/silhouette/lipoaspiration/">
              Lipoaspiration - Liposuccion
            </Link>

            <Link
              {...cssLink}
              to="/chirurgie-esthetique-sous-anesthesie-locale/#liposuccion-douce"
            >
              Liposuccion douce
            </Link>

            <Link {...cssLink} to="/silhouette/liposuccion-culotte-de-cheval/">
              Liposuccion de la culotte de cheval
            </Link>

            <Link {...cssLink} to="/silhouette/mommy-makeover/">
              Mommy makeover
            </Link>

            <Link {...cssLink} to="/silhouette/onda-coolwaves/">
              ONDA Coolwaves
            </Link>

            <Link {...cssLink} to="/silhouette/phototherapie-dynamique/">
              Photothérapie dynamique
            </Link>

            <Link {...cssLink} to="/silhouette/plastie-abdominale/">
              Plastie Abdominale
            </Link>

            <Link {...cssLink} to="/silhouette/poitrine-homme-gynecomastie/">
              Poitrine chez l'homme
            </Link>

            <Link {...cssLink} to="/silhouette/remodelage-fesses/">
              Remodelage des fesses
            </Link>

            <Link {...cssLink} to="/actualites/sisthaema/">
              Sisthaema
            </Link>

            <Link {...cssLink} to="/peau/supprimer-vergetures/">
              Traitement des vergetures
            </Link>

            <Link {...cssLink} to="/silhouette/keybody-lipo/">
              Keybody-Lipo
            </Link>

            <Link {...cssLink} to="/silhouette/keybody-cellulite/">
              Keybody-Cellulite
            </Link>

            <Link {...cssLink} to="/xbody-sculpter-raffermir-son-corps/">
              XBody : Sculpter son corps
            </Link>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              <Zone>
                Sexe <Arrow />
              </Zone>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <Link {...cssLink} to="/sexe/augmentation-point-g/">
              Augmentation du point G
            </Link>

            <Link {...cssLink} to="/sexe/nymphoplastie-reduction-levres/">
              Nymphoplastie : R&eacute;duction des petites l&egrave;vres
            </Link>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              <Zone>
                Jambes <Arrow />
              </Zone>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <Link {...cssLink} to="/silhouette/cryolipolyse-coolsculpting/">
              Cryolipolyse avec Coolsculpting
            </Link>

            <Link {...cssLink} to="/silhouette/keybody-lipo/">
              Keybody-Lipo
            </Link>

            <Link {...cssLink} to="/jambes/lifting-des-cuisses/">
              Lifting des cuisses
            </Link>

            <Link {...cssLink} to="/jambes/lipoaspiration-mollets/">
              Lipoaspiration des mollets
            </Link>

            <Link {...cssLink} to="/jambes/lipostructure-mollet/">
              Lipostructure du mollet
            </Link>

            <Link {...cssLink} to="/silhouette/onda-coolwaves/">
              ONDA Coolwaves
            </Link>

            <Link {...cssLink} to="/jambes/protheses-mollets/">
              Prothèses des mollets
            </Link>

            <Link {...cssLink} to="/silhouette/emsculpt/">
              Sculpter ses fesses avec EMSculpt
            </Link>

            <Link {...cssLink} to="/xbody-sculpter-raffermir-son-corps/">
              X-Body
            </Link>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              <Zone>
                Peau <Arrow />
              </Zone>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <Link {...cssLink} to="/visage/carboxytherapie/">
              Carboxythérapie du visage
            </Link>

            <Link {...cssLink} to="/peau/correction-cicatrices/">
              Chirurgie des cicatrices{" "}
            </Link>

            <Link {...cssLink} to="/peau/chirurgie-tumeurs-cutanees/">
              Chirurgie des tumeurs cutan&eacute;es
            </Link>

            <Link {...cssLink} to="/visage/hydrafacial/">
              HydraFacial
            </Link>

            <Link {...cssLink} to="/peau/keybody-skin/">
              Keybody-Skin
            </Link>

            <Link {...cssLink} to="/peau/laser-icon-correction-imperfections/">
              Laser Icon
            </Link>

            <Link {...cssLink} to="/silhouette/onda-coolwaves/">
              ONDA Coolwaves
            </Link>

            <Link {...cssLink} to="/visage/peeling-combine/">
              Peeling combiné
            </Link>

            <Link {...cssLink} to="/peau/peeling-bioretivalisant-prx-t33/">
              Peeling biorevitalisant PRX-T33
            </Link>

            <Link {...cssLink} to="/peau/PRP-plasma-riche-plaquettes/">
              Plasma Riche en Plaquettes (PRP)
            </Link>

            <Link {...cssLink} to="/regard/plexr/">
              Plexr®
            </Link>

            <Link {...cssLink} to="/visage/couperose-rosacee-erythrose-laser/">
              Rosacée, couperose et érythrose
            </Link>

            <Link {...cssLink} to="/silhouette/keybody-cellulite/">
              Traitement de la cellulite
            </Link>

            <Link {...cssLink} to="/peau/supprimer-vergetures/">
              Traitement des vergetures
            </Link>

            <Link {...cssLink} to="/visage/skinbooster/">
              Skinbooster
            </Link>

            <Link {...cssLink} to="/visage/universkin/">
              Universkin : Cosmétique médicale
            </Link>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </Wrapper>
  );
}

const Wrapper = g.div({
  borderRadius: `4px`,
  backgroundColor: `white`,
  color: `#1f1f1f`,
  border: `1px solid rgba(73,105,126,0.08)`,
  boxShadow: `0 1px 7px rgba(0,0,0,.05)`,
  margin: `2em 0 2em`,
  padding: `.75em`,
  transition: `all 0.15s ease-in-out`,
  ":hover": {
    border: `1px solid rgba(73,105,126,0.18)`,
    boxShadow: `0 1px 9px rgba(0,0,0,.2)`,
    transition: `all 0.1s ease-in-out`,
  },
});

const Title = g.div({
  margin: `0 auto 1em`,
  letterSpacing: `0.05em`,
  fontSize: `1.2em`,
  paddingBottom: `3px`,
  color: `#49697e`,
  borderBottom: `2px solid #49697e`,
  fontWeight: `bold`,
});

const Zone = g.div({
  fontSize: `1.05em`,
  color: `#49697e`,
  fontWeight: `500`,
  padding: `.2em`,
  borderBottom: `1px solid rgba(14, 36, 69, 0.2)`,
  marginBottom: `.35em`,
  position: `relative`,
  "&::focus": {
    outline: `none`,
    color: `red`,
  },
});

const Arrow = g.div({
  display: `inline-block`,
  width: `24px`,
  height: `12px`,
  position: `absolute`,
  top: `50%`,
  right: `0`,
  marginTop: `-6px`,
  "&::before": {
    display: `block`,
    position: `absolute`,
    top: `50%`,
    width: `10px`,
    height: `2px`,
    backgroundColor: `#49697e`,
    content: ` `,
    left: `4px`,
    transform: `rotate(45deg)`,
  },
  "&::after": {
    display: `block`,
    position: `absolute`,
    top: `50%`,
    width: `10px`,
    height: `2px`,
    backgroundColor: `#49697e`,
    content: ` `,
    right: `4px`,
    transform: `rotate(-45deg)`,
  },
});

css.global(".accordion__heading:focus", {
  outline: `none !important`,
});

css.global(".accordion__button", {
  padding: `0 !important`,
  backgroundColor: `white !important`,
  border: `none`,
});

css.global(".accordion__button:focus", {
  outline: `none !important`,
});

css.global(".accordion", {
  border: `none !important`,
});

css.global(".accordion__item", {
  border: `none !important`,
});

css.global(".accordion__panel", {
  padding: `0 !important`,
});

css.global(".accordion__button:before", {
  display: `none !important`,
});

css.global(".accordion__title", {
  letterSpacing: `0em`,
  transition: `all .15s ease-in-out`,
  cursor: `pointer`,
});

css.global(".accordion__title:focus", {
  outline: "none",
  letterSpacing: `.045em`,
  transition: `all .15s ease-in-out`,
});

const cssLink = css({
  display: `block`,
  fontSize: `.9em`,
  color: `#3f3f3f`,
  paddingLeft: `1px`,
  lineHeight: `1.5em`,
  margin: `.55em 0`,
  "&:hover": {
    backgroundColor: `hsla(193,5%,62%,.2)`,
  },
});
